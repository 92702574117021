import { useCallback, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { cn, rmEmptyKeys } from '@components/helper'
import { Text } from '@components/ui'
import Button from '@components/control/Button'
import { ColorSwatch } from '@components/product'
import { atobID } from '@lib/utils/tools'
import usePrice from '@shopify/product/use-price'
import Picture from '@components/ui/Picture'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { Cta } from '@components/product'
import { normalizeStorefrontProduct as normalize } from '@components/normalize'
import BannerLink from '@components/common/BannerLink'

import s from './ProductCard.module.css'

const ProductCard = ({
  product,
  imgProps,
  className,
  text = {},
  model = 'simple',
  sales,
  index,
  metafields = {},
  coupons = [],
  pageHandle = '',
  pageRef = '',
  handleOpenProductPop,
  isRecommends = false,
  prodIndex = 0,
  data = {},
  cns = {},
}) => {
  const { locale } = useRouter()
  const currentVariant = useMemo(
    () =>
      (model === 'filter' || model === 'series_filter') &&
      product.filter_variants
        ? product?.filter_variants?.[0]
        : product?.variants?.[0],
    [model, product]
  )
  const coupon = coupons?.find((i) => i.sku === currentVariant?.sku) || {}

  const { price } = usePrice({
    amount: Number(currentVariant?.price),
    currencyCode: product?.price?.currencyCode,
  })

  const { price: discount_price } = usePrice({
    amount: Number(product?.variant_price4wscode || 0),
    currencyCode: product?.price?.currencyCode,
  })

  const { price: sale_price } = usePrice({
    amount: Number(coupon?.variant_price4wscode),
    currencyCode: product?.price?.currencyCode,
  })

  const rootClassName = cn(
    s.ProductCardRoot,
    { [s.simple]: model === 'filter' || model === 'series_filter' },
    { [s.ProductCardRootV2]: model === 'filterV2' },
    className
  )

  const availableForSale = useMemo(() => {
    if (currentVariant?.price >= 999999) return false

    return model === 'filter' ||
      model === 'series_filter' ||
      model === 'filterV2'
      ? product?.availableForSale
      : currentVariant?.availableForSale
  }, [
    currentVariant?.availableForSale,
    currentVariant?.price,
    model,
    product?.availableForSale,
  ])

  const getRef = (model) => {
    if (model === 'series_filter') {
      return '&ref=seriesBuy'
    }
    if (model === 'filter') {
      return '&ref=collectionBuy'
    }
    if (model === 'filterV2') {
      return pageRef
    }
    return ''
  }

  const productLink = `${locale === 'us' ? '' : `/${locale}`}/products/${
    product.handle
  }?variant=${atobID(currentVariant?.id)}${getRef(model)}`

  // 在商品列表页点击图片Learn more, 星级等能够跳转到商品详情页的事件
  // const handleBuyPushLayerClick = useCallback(() => {
  //   pageGTMEvent({
  //     event: 'ga4Event',
  //     event_name: 'select_item',
  //     event_parameters: {
  //       page_group: 'Product Detail Page_' + pageHandle,
  //       action: '', //可不要
  //       position: '',
  //       currency: product?.price?.currencyCode,
  //       item_list_id: '',
  //       item_list_name: 'collections_' + pageHandle,
  //       items: [
  //         {
  //           item_id: product?.variants?.[0]?.sku,
  //           item_name: product?.name,
  //           item_brand: product?.vendor,
  //           item_category: product?.productType,
  //           item_variant: product?.variants?.[0]?.name,
  //           price: product?.price?.value,
  //           index: index,
  //         },
  //       ],
  //     },
  //   })
  // }, [product, index])

  const tag = product.tags?.find((tag) => tag in (text?.tagToDescription || {}))
  const [hover, setHover] = useState(false)
  // const [openProductPop, setOpenProductPop] = useState(false)

  // console.log('tag:', tag)
  // console.log('tagToDescription:', text.tagToDescription)

  return (
    <ProductCardBox
      data={data}
      rootClassName={rootClassName}
      product={product}
      prodIndex={prodIndex}
      currentVariant={currentVariant}
      model={model}
      setHover={setHover}
      pageRef={pageRef}
      productLink={productLink}
    >
      <div className={s.filterV2}>
        <div
          className={s.imageContainer}
          onClick={(e) => {
            if (data?.mainImagePop) {
              e.stopPropagation()
              e.preventDefault()
              handleOpenProductPop({
                sale_price: sale_price,
                price: price,
                sales: sales,
                product: product,
              })
              isRecommends && RecommendsGTM({ product, prodIndex })
            }
          }}
        >
          <Picture
            alt={product.name || 'Product Image'}
            className={s.productImage}
            source={currentVariant?.images?.url || product.images?.[0]?.url}
            {...imgProps}
          />
          {data?.cardType === 'tile' && <BannerLink to={productLink} />}
        </div>
        <div className={s.infoBox}>
          <Text
            html={
              text.tagToDescription?.[tag]?.label ||
              text.tagToDescription?.[tag] ||
              ''
            }
            className="min-h-[28px] pb-1 text-[14px] font-[600] text-[#FF5C00] md:min-h-[16px] md:text-[13px]"
            variant="paragraph"
          />
          <div
            className={cn(
              data?.cardType !== 'tile' && s.nameBox,
              cns.nameBox,
              'relative'
            )}
          >
            <Text
              html={product.name}
              className={cn(data?.cardType === 'tile' && s.boaName, s.name, {
                [s.hover]: hover,
              })}
              variant="paragraph"
              onClick={(e) => {
                if (data?.mainImagePop) {
                  e.stopPropagation()
                  e.preventDefault()
                  handleOpenProductPop({
                    sale_price: sale_price,
                    price: price,
                    sales: sales,
                    product: product,
                  })
                  isRecommends && RecommendsGTM({ product, prodIndex })
                }
              }}
            />
            {(hover || data?.cardType === 'tile') && (
              <div className={cn(s.swatch)}>
                {product.options &&
                  product.options.map((opt) => {
                    if (opt.displayName == 'color') {
                      return opt.values.map((v, i) => {
                        return (
                          <ColorSwatch
                            key={`${opt.id}-${i}`}
                            imagePath={product?.images?.[0]?.url}
                            className={s.color}
                            color={v.hexColors ? v.hexColors[0] : v.label}
                          />
                        )
                      })
                    }
                    return ''
                  })}
              </div>
            )}
            {data?.cardType === 'tile' && <BannerLink to={productLink} />}
          </div>
          {availableForSale ? (
            <>
              <div className={s.price}>
                {coupon?.title ? (
                  <p>
                    <span className="mr-1 text-[20px] font-semibold text-[#333] md:text-[16px]">
                      {sale_price}
                    </span>
                    <span className="text-[16px] font-medium text-[#AAA] line-through md:text-[13px]">
                      {price}
                    </span>
                  </p>
                ) : (
                  <p>
                    <span className="mr-1 text-[20px] font-semibold text-[#333] md:text-[16px]">
                      {price}
                    </span>
                  </p>
                )}
                {data?.cardType !== 'tile' && (
                  <>
                    <div
                      className={cn(
                        s.openPop,
                        'hidden bg-brand-color md:block'
                      )}
                      onClick={(e) => {
                        if (!data?.btnLink) {
                          e.stopPropagation()
                          e.preventDefault()
                          handleOpenProductPop({
                            sale_price: sale_price,
                            price: price,
                            sales: sales,
                            product: product,
                          })
                          isRecommends && RecommendsGTM({ product, prodIndex })
                        }
                      }}
                    >
                      <Picture
                        className="inline-block h-[22px] w-[22px]"
                        source="https://cdn.shopify.com/s/files/1/0508/1815/4652/files/icon_cart.svg?v=1694057353"
                      />
                    </div>
                    <div
                      className={cn(
                        'absolute w-full md:hidden',
                        hover ? 'opacity-100' : 'opacity-0',
                        'duration-300'
                      )}
                    >
                      <Button
                        cns={s}
                        className={s.buyBtn}
                        onClick={(e) => {
                          if (!data?.btnLink) {
                            e.stopPropagation()
                            e.preventDefault()
                            handleOpenProductPop({
                              sale_price: sale_price,
                              price: price,
                              sales: sales,
                              product: product,
                            })
                            isRecommends &&
                              RecommendsGTM({ product, prodIndex })
                          }
                        }}
                      >
                        <Picture
                          className="inline-block h-[28px] w-[22px]"
                          source="https://cdn.shopify.com/s/files/1/0508/1815/4652/files/icon_cart.svg?v=1694057353"
                        />
                        <span className="leading-0 pl-[5px] text-[22px] font-semibold">
                          {coupon?.title ? sale_price : price}
                        </span>
                      </Button>
                    </div>
                  </>
                )}
              </div>
              {data?.buttons && (
                <div className="md:mt-[10px]">
                  <Cta
                    s={{
                      buttons: 'flex flex-col gap-[5px]',
                    }}
                    copy={data}
                    product={normalize(product, { products: [product] })}
                    buttons={data?.buttons}
                  />
                </div>
              )}
            </>
          ) : (
            <div className={s.price}>
              <span className="pr-2 text-[22px] font-medium text-gray-300 md:text-[16px]">
                {text?.soldOut}
              </span>
            </div>
          )}
        </div>
        {sales && (
          <div className="absolute left-[24px] top-0 h-[60px] w-[60px] text-base font-bold text-white md:left-[12px]  md:h-[45px] md:w-[45px]">
            <p className="relative left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 break-words px-1 py-[2px] text-center leading-[1.2] md:text-[12px]">
              {sales}
            </p>
            {text?.saleBg && (
              <Picture
                alt="sales"
                className="absolute left-0 top-0 w-full object-fill"
                source={text?.saleBg}
                src={text?.saleBg}
              />
            )}
          </div>
        )}
      </div>
    </ProductCardBox>
  )
}

export default ProductCard

export const RecommendsGTM = ({ product, prodIndex }) => {
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'select_item',
    event_parameters: {
      page_group: 'Home Page',
      item_list_name: 'Home_Page_Bundle',
      items: [
        {
          item_id: product?.variants?.[0]?.sku, //读取被推荐产品的SKU
          item_name: product?.name,
          item_variant: product?.variants?.[0]?.name,
          price: product?.variants[0]?.price, //读取被推荐产品展示的折扣价,无价格则空
          index: prodIndex, //读取被推荐的位置，如1,2,3,4,5
        },
      ],
    },
  })
}

const ProductCardBox = ({
  data,
  rootClassName,
  product,
  prodIndex,
  currentVariant,
  model,
  setHover,
  pageRef,
  productLink,
  ...rest
}) => {
  return data?.cardType === 'tile' ? (
    <div className={rootClassName} {...rest} />
  ) : (
    <a
      className={rootClassName}
      style={rmEmptyKeys({ backgroundColor: data?.itemBgColor })}
      aria-label={product.name}
      onClick={() => {
        // handleBuyPushLayerClick()
        isRecommends && RecommendsGTM({ product, prodIndex })
      }}
      href={productLink}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...rest}
    />
  )
}
