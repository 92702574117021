import { cn, rmEmptyKeys } from '@components/helper'
import styles from './index.module.css'
import { useUI } from '@components/ui/context'
import { Text, Picture } from '@components/ui'
import { useCallback, useState, useEffect, useMemo } from 'react'
import { useFilterCollection } from 'lib/shopify/api/collection'
import CollectionSwiper from '../../Gifting/CollectionSwiper'
import getProductCouponsByProducts from '@lib/apis/get-product-coupons-by-products'
import getProductPersonalizeInHome from '@lib/apis/get-product-personalize-in-home'
import { getGaClientId } from '@lib/utils/tools'
import useProduct from '@shopify/product/use-product'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { atobID } from '@lib/utils/tools'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

const Index = ({
  data,
  id,
  pageHandle,
  metafields,
  locale,
  pageType,
  s: gs = {},
  page_group,
}) => {
  const s = { ...styles, ...gs }

  const { gtmReady } = useUI()

  const [coupons, setCoupons] = useState({})
  const [current, setCurrent] = useState(0)
  const [resultProducts, setResultProducts] = useState([])
  const [isRecommends, setIsRecommends] = useState(false)
  const [recommendsProductsHandle, setRecommendsProductsHandle] = useState([])

  useEffect(() => {
    const offsetWidth = document?.querySelector('body')?.offsetWidth || 0
    if (offsetWidth <= 769 && current) {
      document.querySelector(`.${s.col} .${s.active}`).scrollIntoView({
        inline: 'center',
        block: 'nearest',
        behavior: 'smooth',
      })
    }
    setIsRecommends(data?.list[current]?.api_recommend || false)
  }, [current])

  // 推荐产品
  const filter_values =
    pageType === 'charger'
      ? ['small_charger']
      : ['big_charger', 'middle_charger']

  const getRecommendsProductsHandle = async () => {
    const clientId = await getGaClientId()
    const result = await getProductPersonalizeInHome({
      locale,
      clientId,
      limit: Number(data?.first || 4),
      filter_values,
    })
    setRecommendsProductsHandle(
      result?.data?.length > 0 ? result?.data?.map((item) => item?.handle) : []
    )
  }
  useEffect(() => {
    let api_recommend = data?.list?.some((item) => item?.api_recommend)
    if (api_recommend && gtmReady) {
      getRecommendsProductsHandle()
    }
  }, [data?.list, gtmReady])

  const { data: recommendsProducts } = useProduct({
    handles: recommendsProductsHandle?.join(','),
  })

  const { data: resultData, isLoading } = useFilterCollection(
    data?.list[current]?.handle,
    { first: data?.first || 12 }
  )

  useEffect(() => {
    ScrollTrigger && ScrollTrigger.refresh()
  }, [coupons, isLoading])

  useEffect(() => {
    setResultProducts(
      isRecommends ? recommendsProducts?.products : resultData?.products
    )
  }, [resultData?.products, recommendsProducts?.products, isRecommends])

  const handles = useMemo(() => {
    return resultProducts?.map((item) => item.handle)
  }, [resultProducts])

  const getCoupons = useCallback(async () => {
    if (handles?.length > 0) {
      const result = await getProductCouponsByProducts(
        locale,
        resultProducts?.map((item) => item.handle),
        'handles[]'
      )
      setCoupons(result || {})
    }
  }, [handles?.length])

  useEffect(() => {
    getCoupons()
  }, [getCoupons])

  return (
    <section
      className={cn('layer', s.featuredWrap)}
      id={id}
      style={rmEmptyKeys({ backgroundColor: data?.bgColor })}
    >
      <div className="content">
        {data?.title && (
          <Text
            className={s.title}
            html={data?.title}
            variant="sectionHeading"
          />
        )}
        {data?.subtitle && (
          <Text
            className={s.subtitle}
            html={data?.subtitle}
            variant="paragraph"
          />
        )}

        <div
          className={cn(s.col, {
            ['md:!justify-center']: data?.list.length <= 2,
          })}
        >
          {data?.list.map((item, index) => {
            // 推荐产品没加载出来之前不展示tab
            return item?.api_recommend ? (
              recommendsProducts?.products?.length > 0 ? (
                <button
                  onClick={() => {
                    if (index != current) {
                      setCurrent(index)
                    }
                    recommendGTM(recommendsProducts?.products)
                  }}
                  className={cn(s.itemBtn, { [s.active]: current == index })}
                  key={index}
                >
                  {item?.label}
                </button>
              ) : null
            ) : (
              <button
                onClick={() => {
                  if (index != current) {
                    setCurrent(index)
                  }
                }}
                className={cn(s.itemBtn, { [s.active]: current == index })}
                key={index}
              >
                {item?.label}
              </button>
            )
          })}
        </div>

        <CollectionSwiper
          id={id}
          data={data}
          current={current}
          coupons={coupons}
          metafields={metafields}
          pageHandle={pageHandle}
          products={resultProducts || []}
          isRecommends={isRecommends}
          page_group={page_group}
        />
      </div>
    </section>
  )
}

export default Index

export const recommendGTM = (products) => {
  let items = products?.map((item, index) => {
    const variant = item?.variants?.[0]
    return {
      item_id: variant?.sku,
      item_name: item?.name,
      item_variant: variant?.name,
      price: variant?.price,
      index: index, //表示该产品在列表中的展示顺序
    }
  })
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'view_item_list',
    event_parameters: {
      page_group: 'Home Page',
      item_list_name: 'Home_Page_Bundle',
      items,
    },
  })
}
