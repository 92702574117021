import fetcher from '@lib/fetcher'
import { I18N_STORE_DOMAIN } from '@shopify/const'
const getProductCouponsByProducts = async (
  locale,
  handles,
  repeatKey,
  formatData
) => {
  //https://shopifyservices-next.anker.com/api/coupons/by_products?handles[]=flare&shopify_domain=anker-ost-test.myshopify.com
  const url = `/api/multipass/shopifyservices/coupons/by_products`

  const result = await fetcher({
    url,
    locale,
    repeatKey,
    method: 'GET',
    body: {
      'handles[]': handles,
      shopify_domain: I18N_STORE_DOMAIN[locale],
    },
  })

  if (!result?.errors && result?.data) {
    const resultData = result?.data
    if (formatData) {
      return formatData(resultData)
    } else {
      return resultData
    }
  } else {
    return false
  }
}

export default getProductCouponsByProducts
