import { cn, rmEmptyKeys } from '@components/helper'
import s from './index.module.css'

import { useEffect, useState } from 'react'
import { Navigation } from 'swiper/modules'
import { Picture } from '@components/ui'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ProductCardPop } from '@components/product'

import ProductCard from '../../HomeCharger/ProductCard'

import { ArrowRightGifting, ArrowLeftGifting } from '@components/icons/next'

import 'swiper/css'
import 'swiper/css/navigation'

const Index = ({
  metafields,
  products,
  pageHandle,
  coupons,
  data,
  id,
  current,
  isRecommends = false,
  page_group,
}) => {
  const { shop_filters_pair, shopCommon, discounts } = metafields

  const [swiper, setSwiper] = useState(null)

  const [openProductPop, setOpenProductPop] = useState(false)
  const [productData, setProductData] = useState({
    sale_price: null,
    price: null,
    sales: null,
    product: null,
  })

  const handleOpenProductPop = (data) => {
    setOpenProductPop(true)
    setProductData({
      sale_price: data.sale_price,
      price: data.price,
      sales: data.sales,
      product: data.product,
    })
  }

  const confirmCoupons = (product) => {
    const currentVariant = product?.variants[0] || {}
    const firstCoupon =
      coupons[product?.handle] &&
      coupons[product?.handle]?.find((v) => v.sku === currentVariant.sku)
    const coupon = firstCoupon
      ? firstCoupon
      : coupons[product?.handle] && coupons[product?.handle][0]
    if (coupon && !firstCoupon) {
      return coupon?.available_for_sale ? discounts?.sale : false
    }
    if (coupon && coupon.value_type === 'fixed_amount') {
      return `${coupon.value_style} ${discounts?.off}`
    }
    if (coupon && coupon.value_type === 'percentage') {
      return `${Math.abs(parseInt(coupon.value))}% ${discounts?.off}`
    }
    return false
  }

  const settings = {
    speed: 400,
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 13,
    breakpoints: {
      320: {
        slidesPerView: 1.2,
        slidesPerGroup: 1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      1280: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
    },
    slideToClickedSlide: true,
    modules: [Navigation],
    navigation: {
      nextEl: `#${id} .${s.button_next}`,
      prevEl: `#${id} .${s.button_pre}`,
    },
  }

  useEffect(() => {
    swiper?.slideTo(0)
  }, [current, swiper])

  return (
    <>
      <div className={s.swiperWrap}>
        {data?.layout == 'center' && products?.length < 4 && (
          <style global jsx>
            {`
              .${s.layoutSwiper} .swiper-wrapper {
                display: flex;
                justify-content: center;
              }
              @media (max-width: 769px) {
                .${s.layoutSwiper} .swiper-wrapper {
                  display: flex;
                  justify-content: flex-start;
                }
              }
            `}
          </style>
        )}
        <Swiper
          {...settings}
          className={cn(s.swiper, s.layoutSwiper)}
          onSwiper={(swiper) => setSwiper(swiper)}
        >
          {products?.map((product, index) => (
            <SwiperSlide key={index} className="!flex !h-[unset]">
              <ProductCard
                key={index}
                data={data}
                prodIndex={index}
                model="filterV2"
                product={product}
                pageRef={data?.ref}
                metafields={metafields}
                pageHandle={pageHandle}
                page_group={page_group}
                className="animated fadeIn"
                sales={confirmCoupons(product)}
                coupons={coupons[product?.handle]}
                handleOpenProductPop={handleOpenProductPop}
                text={{
                  ...shop_filters_pair?.filter_txt,
                  soldOut:
                    product?.metafields?.preRender?.soldOut ||
                    shopCommon?.soldOut,
                  saleBg:
                    data?.Mark_img ||
                    shop_filters_pair?.filter_txt?.Mark_img ||
                    discounts?.Mark_img,
                  tagToDescription: shopCommon?.tagToDescription,
                }}
                imgProps={{
                  loading: index === 0 ? 'eager' : 'lazy',
                }}
                isRecommends={isRecommends}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className={cn('swiper-button-next', s.button_next)}
          style={rmEmptyKeys({ backgroundColor: data?.itemBgColor })}
        >
          <ArrowRightGifting className="!w-[35px] xxl:!w-[20px]" />
        </div>
        <div
          className={cn('swiper-button-prev', s.button_pre)}
          style={rmEmptyKeys({ backgroundColor: data?.itemBgColor })}
        >
          <ArrowLeftGifting className="!w-[35px] xxl:!w-[20px]" />
        </div>
      </div>

      {openProductPop && (
        <ProductCardPop
          page_group={page_group || 'Home Page'}
          pageHandle={pageHandle}
          coupons={coupons[productData?.product?.handle]}
          sale_price={productData?.sale_price}
          price={productData?.price}
          sales={productData?.sales}
          product={productData?.product}
          setOpenProductPop={setOpenProductPop}
          metafields={metafields}
          text={{
            ...shop_filters_pair?.filter_txt,
            soldOut:
              productData?.product?.metafields?.preRender?.soldOut ||
              shopCommon?.soldOut,
            saleBg:
              data?.Mark_img ||
              shop_filters_pair?.filter_txt?.Mark_img ||
              discounts?.Mark_img,
            tagToDescription: shopCommon?.tagToDescription,
          }}
          isRecommends={isRecommends}
        ></ProductCardPop>
      )}
    </>
  )
}

export default Index
